import { post } from '@/utils/request';
// 跟进记录
const logList = (data) => {
    return post({
        url: '/admin/hierarchy.Apply/logList',
        data: data
    })
}

//申请列表
const applyList = (data) => {
    return post({
        url: '/admin/hierarchy.Apply/list',
        data: data
    })
}

//添加跟进进度
const logCreate = (data) => {
    return post({
        url: '/admin/hierarchy.Apply/logCreate',
        data: data
    })
}

export {
    logList,
    applyList,
    logCreate
}