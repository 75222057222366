<template>
  <div>
    <div class="content">
      <div class="card">
        <el-button type="primary" plain @click="handleAdd(0)" icon="el-icon-upload2">导出</el-button>
        <div class="line"></div>
        <el-input placeholder="请输入手机号码" style="width: 200px;margin-right:10px;"></el-input>
        <el-date-picker
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
        <el-button type="primary" @click="handleAdd(0)" style="margin-left:10px;">搜索</el-button>
      </div>
      <div class="table">
        <el-table :data="list" stripe style="width: 100%">
          <el-table-column align="center" prop="ha_company" label="公司名称"></el-table-column>
          <el-table-column align="center" prop="ha_name" label="联系人"></el-table-column>
          <el-table-column align="center" prop="ha_phone" label="手机号码"></el-table-column>
          <el-table-column align="center" prop="create_time" label="提交时间"></el-table-column>
          <el-table-column align="center" prop="ha_is_trace_text" label="是否跟进"></el-table-column>
          <el-table-column align="center" prop="realname" label="跟进人"></el-table-column>
          <el-table-column align="center" fixed="right" label="操作">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.select"
                placeholder="请选择"
                @change="handleChange($event, scope.row)"
              >
                <el-option label="跟进" :value="0"></el-option>
              </el-select>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagina">
          <el-pagination background layout="prev, pager, next" :total="total"></el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="logVisible"
      title="跟进"
      width="30%"
      :destroy-on-close="true"
      :before-close="goCancel"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="跟进备注">
          <el-input type="textarea" :rows="2" placeholder="请输入跟进备注" v-model="form.content"></el-input>
        </el-form-item>
      </el-form>
      <div class="logLine"></div>
      <div class="title">
        <div class="span"></div>跟进记录
      </div>
      <div style="height: 350px;overflow:auto">
        <el-steps direction="vertical" :space="80" :active="1">
          <el-step
            v-for="(item, index) in logList"
            :key="index"
            :title="item.realname"
            :description="item.content"
          ></el-step>
        </el-steps>
      </div>
      <div class="logLine"></div>
      <div style="margin:20px auto 0 auto;width: 30%;display:flex;flex-desition:row">
        <el-button @click="goCancel">取消</el-button>
        <el-button type="primary" @click="submit()">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { applyList, logList, logCreate } from "@/api/freeFollowUpManage/index";
export default {
  data() {
    return {
      list: [],
      total: 0,
      logVisible: false,
      form: {},
      logList: [],
      hierarchy_apply_id: "",
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    /*
     *  跟进列表
     */
    fetchData() {
      applyList().then((res) => {
        if (res.code === 1) {
          console.log(res.data.list);
          this.list = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    /*
     *  跟进记录
     */
    fetchLog(id) {
      this.hierarchy_apply_id = id;
      const data = {
        hierarchy_apply_id: id,
      };
      logList(data).then((res) => {
        if (res.code === 1) {
          this.logList = res.data.list;
        }
      });
    },
    /*
     *  操作
     */
    handleChange(e, current) {
      console.log(current);
      if (e === 0) {
        this.logVisible = true;
        this.fetchLog(current.hierarchy_apply_id);
      }
    },
    /*
     *  关闭弹窗
     */
    goCancel() {
      this.logVisible = false;
    },
    /*
     *  提交跟进记录
     */
    submit() {
      const data = {
        content: this.form.content,
        hierarchy_apply_id: this.hierarchy_apply_id,
      };
      logCreate(data).then((res) => {
        if (res.code === 1) {
          this.$message({
            message: "跟进提交成功！",
            type: "success",
          });
          this.logVisible = false;
          this.fetchData();
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .line {
  display: inline-block;
  vertical-align: middle;
  margin: 0 30px;
  width: 1px;
  height: 40px;
  background: #e9e9e9;
}

/deep/ .logLine {
  margin: 20px auto 0 auto;
  width: 95%;
  height: 1px;
  background: #ebeef5;
}

/deep/ .title {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  width: 100%;
  height: 60px;
  line-height: 60px;

  .span {
    margin: auto 10px auto 0;
    width: 4px;
    height: 16px;
    background: #5493f5;
    border-radius: 2px;
  }
}
</style>
